import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _77f288a0 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _3f13d604 = () => interopDefault(import('../pages/m/404.vue' /* webpackChunkName: "pages/m/404" */))
const _6928973a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2ba1bcd6 = () => interopDefault(import('../pages/m/_lang/index.vue' /* webpackChunkName: "pages/m/_lang/index" */))
const _5da2e29e = () => interopDefault(import('../pages/m/_lang/event.vue' /* webpackChunkName: "pages/m/_lang/event" */))
const _16567aa1 = () => interopDefault(import('../pages/m/_lang/event/index.vue' /* webpackChunkName: "pages/m/_lang/event/index" */))
const _6e569d6e = () => interopDefault(import('../pages/m/_lang/event/_id.vue' /* webpackChunkName: "pages/m/_lang/event/_id" */))
const _009e8f60 = () => interopDefault(import('../pages/m/_lang/map.vue' /* webpackChunkName: "pages/m/_lang/map" */))
const _175a0de3 = () => interopDefault(import('../pages/m/_lang/map/index.vue' /* webpackChunkName: "pages/m/_lang/map/index" */))
const _0e6fa5ea = () => interopDefault(import('../pages/m/_lang/map/_id.vue' /* webpackChunkName: "pages/m/_lang/map/_id" */))
const _494faa30 = () => interopDefault(import('../pages/m/_lang/media.vue' /* webpackChunkName: "pages/m/_lang/media" */))
const _78fa392a = () => interopDefault(import('../pages/m/_lang/media/index.vue' /* webpackChunkName: "pages/m/_lang/media/index" */))
const _528ebf32 = () => interopDefault(import('../pages/m/_lang/media/imageList.vue' /* webpackChunkName: "pages/m/_lang/media/imageList" */))
const _1d91eb5c = () => interopDefault(import('../pages/m/_lang/media/videoList.vue' /* webpackChunkName: "pages/m/_lang/media/videoList" */))
const _5e4b03ff = () => interopDefault(import('../pages/m/_lang/news.vue' /* webpackChunkName: "pages/m/_lang/news" */))
const _9c43617c = () => interopDefault(import('../pages/m/_lang/news/index.vue' /* webpackChunkName: "pages/m/_lang/news/index" */))
const _0bdb3a2a = () => interopDefault(import('../pages/m/_lang/news/_id.vue' /* webpackChunkName: "pages/m/_lang/news/_id" */))
const _dd403dc2 = () => interopDefault(import('../pages/m/_lang/notices.vue' /* webpackChunkName: "pages/m/_lang/notices" */))
const _b83e573c = () => interopDefault(import('../pages/m/_lang/notices/index.vue' /* webpackChunkName: "pages/m/_lang/notices/index" */))
const _70d63541 = () => interopDefault(import('../pages/m/_lang/roles.vue' /* webpackChunkName: "pages/m/_lang/roles" */))
const _24dc0704 = () => interopDefault(import('../pages/m/_lang/roles/index.vue' /* webpackChunkName: "pages/m/_lang/roles/index" */))
const _d1f00b28 = () => interopDefault(import('../pages/m/_lang/roles/_id.vue' /* webpackChunkName: "pages/m/_lang/roles/_id" */))
const _17462dc0 = () => interopDefault(import('../pages/m/_lang/others/contact.vue' /* webpackChunkName: "pages/m/_lang/others/contact" */))
const _6c751c55 = () => interopDefault(import('../pages/m/_lang/others/instructions.vue' /* webpackChunkName: "pages/m/_lang/others/instructions" */))
const _2cea5f62 = () => interopDefault(import('../pages/m/_lang/others/policy.vue' /* webpackChunkName: "pages/m/_lang/others/policy" */))
const _7efc3935 = () => interopDefault(import('../pages/m/_lang/others/service.vue' /* webpackChunkName: "pages/m/_lang/others/service" */))
const _18496e78 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _4a4a9440 = () => interopDefault(import('../pages/_lang/event.vue' /* webpackChunkName: "pages/_lang/event" */))
const _5266eac3 = () => interopDefault(import('../pages/_lang/event/index.vue' /* webpackChunkName: "pages/_lang/event/index" */))
const _5db27c2a = () => interopDefault(import('../pages/_lang/event/_id.vue' /* webpackChunkName: "pages/_lang/event/_id" */))
const _1d2b7cfc = () => interopDefault(import('../pages/_lang/map.vue' /* webpackChunkName: "pages/_lang/map" */))
const _1fac1e85 = () => interopDefault(import('../pages/_lang/map/index.vue' /* webpackChunkName: "pages/_lang/map/index" */))
const _5a49fe2d = () => interopDefault(import('../pages/_lang/map/_id.vue' /* webpackChunkName: "pages/_lang/map/_id" */))
const _700046ec = () => interopDefault(import('../pages/_lang/media.vue' /* webpackChunkName: "pages/_lang/media" */))
const _00d958e6 = () => interopDefault(import('../pages/_lang/media/index.vue' /* webpackChunkName: "pages/_lang/media/index" */))
const _32c64e54 = () => interopDefault(import('../pages/_lang/media/imageList.vue' /* webpackChunkName: "pages/_lang/media/imageList" */))
const _5d22cd18 = () => interopDefault(import('../pages/_lang/media/videoList.vue' /* webpackChunkName: "pages/_lang/media/videoList" */))
const _f214d3c6 = () => interopDefault(import('../pages/_lang/news.vue' /* webpackChunkName: "pages/_lang/news" */))
const _98635a40 = () => interopDefault(import('../pages/_lang/news/index.vue' /* webpackChunkName: "pages/_lang/news/index" */))
const _4ad8e570 = () => interopDefault(import('../pages/_lang/news/_id.vue' /* webpackChunkName: "pages/_lang/news/_id" */))
const _1d95c404 = () => interopDefault(import('../pages/_lang/notices/index.vue' /* webpackChunkName: "pages/_lang/notices/index" */))
const _5d7de6e3 = () => interopDefault(import('../pages/_lang/roles.vue' /* webpackChunkName: "pages/_lang/roles" */))
const _60ec7726 = () => interopDefault(import('../pages/_lang/roles/index.vue' /* webpackChunkName: "pages/_lang/roles/index" */))
const _c14be9e4 = () => interopDefault(import('../pages/_lang/roles/_id.vue' /* webpackChunkName: "pages/_lang/roles/_id" */))
const _9d742484 = () => interopDefault(import('../pages/_lang/others/contact.vue' /* webpackChunkName: "pages/_lang/others/contact" */))
const _3a2c4a77 = () => interopDefault(import('../pages/_lang/others/instructions.vue' /* webpackChunkName: "pages/_lang/others/instructions" */))
const _b2c161f8 = () => interopDefault(import('../pages/_lang/others/policy.vue' /* webpackChunkName: "pages/_lang/others/policy" */))
const _3be53dd3 = () => interopDefault(import('../pages/_lang/others/service.vue' /* webpackChunkName: "pages/_lang/others/service" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _77f288a0,
    name: "404"
  }, {
    path: "/m/404",
    component: _3f13d604,
    name: "m-404"
  }, {
    path: "/",
    component: _6928973a,
    name: "index"
  }, {
    path: "/m/:lang",
    component: _2ba1bcd6,
    name: "m-lang"
  }, {
    path: "/m/:lang?/event",
    component: _5da2e29e,
    children: [{
      path: "",
      component: _16567aa1,
      name: "m-lang-event"
    }, {
      path: ":id",
      component: _6e569d6e,
      name: "m-lang-event-id"
    }]
  }, {
    path: "/m/:lang?/map",
    component: _009e8f60,
    children: [{
      path: "",
      component: _175a0de3,
      name: "m-lang-map"
    }, {
      path: ":id",
      component: _0e6fa5ea,
      name: "m-lang-map-id"
    }]
  }, {
    path: "/m/:lang?/media",
    component: _494faa30,
    children: [{
      path: "",
      component: _78fa392a,
      name: "m-lang-media"
    }, {
      path: "imageList",
      component: _528ebf32,
      name: "m-lang-media-imageList"
    }, {
      path: "videoList",
      component: _1d91eb5c,
      name: "m-lang-media-videoList"
    }]
  }, {
    path: "/m/:lang?/news",
    component: _5e4b03ff,
    children: [{
      path: "",
      component: _9c43617c,
      name: "m-lang-news"
    }, {
      path: ":id",
      component: _0bdb3a2a,
      name: "m-lang-news-id"
    }]
  }, {
    path: "/m/:lang?/notices",
    component: _dd403dc2,
    children: [{
      path: "",
      component: _b83e573c,
      name: "m-lang-notices"
    }]
  }, {
    path: "/m/:lang?/roles",
    component: _70d63541,
    children: [{
      path: "",
      component: _24dc0704,
      name: "m-lang-roles"
    }, {
      path: ":id",
      component: _d1f00b28,
      name: "m-lang-roles-id"
    }]
  }, {
    path: "/m/:lang?/others/contact",
    component: _17462dc0,
    name: "m-lang-others-contact"
  }, {
    path: "/m/:lang?/others/instructions",
    component: _6c751c55,
    name: "m-lang-others-instructions"
  }, {
    path: "/m/:lang?/others/policy",
    component: _2cea5f62,
    name: "m-lang-others-policy"
  }, {
    path: "/m/:lang?/others/service",
    component: _7efc3935,
    name: "m-lang-others-service"
  }, {
    path: "/:lang",
    component: _18496e78,
    name: "lang"
  }, {
    path: "/:lang/event",
    component: _4a4a9440,
    children: [{
      path: "",
      component: _5266eac3,
      name: "lang-event"
    }, {
      path: ":id",
      component: _5db27c2a,
      name: "lang-event-id"
    }]
  }, {
    path: "/:lang/map",
    component: _1d2b7cfc,
    children: [{
      path: "",
      component: _1fac1e85,
      name: "lang-map"
    }, {
      path: ":id",
      component: _5a49fe2d,
      name: "lang-map-id"
    }]
  }, {
    path: "/:lang/media",
    component: _700046ec,
    children: [{
      path: "",
      component: _00d958e6,
      name: "lang-media"
    }, {
      path: "imageList",
      component: _32c64e54,
      name: "lang-media-imageList"
    }, {
      path: "videoList",
      component: _5d22cd18,
      name: "lang-media-videoList"
    }]
  }, {
    path: "/:lang/news",
    component: _f214d3c6,
    children: [{
      path: "",
      component: _98635a40,
      name: "lang-news"
    }, {
      path: ":id",
      component: _4ad8e570,
      name: "lang-news-id"
    }]
  }, {
    path: "/:lang/notices",
    component: _1d95c404,
    name: "lang-notices"
  }, {
    path: "/:lang/roles",
    component: _5d7de6e3,
    children: [{
      path: "",
      component: _60ec7726,
      name: "lang-roles"
    }, {
      path: ":id",
      component: _c14be9e4,
      name: "lang-roles-id"
    }]
  }, {
    path: "/:lang/others/contact",
    component: _9d742484,
    name: "lang-others-contact"
  }, {
    path: "/:lang/others/instructions",
    component: _3a2c4a77,
    name: "lang-others-instructions"
  }, {
    path: "/:lang/others/policy",
    component: _b2c161f8,
    name: "lang-others-policy"
  }, {
    path: "/:lang/others/service",
    component: _3be53dd3,
    name: "lang-others-service"
  }, {
    path: "*",
    component: _77f288a0
  }, {
    path: "/m/*",
    component: _3f13d604
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
