
import Float from './float.js';
export default {
  mixins: [Float],
  data () {
    return {
      isIOS: false
    }
  },
  mounted () {
    let ua = navigator && navigator.userAgent.toLowerCase();
    if ((ua.includes('iphone')) || (ua.includes('ipad'))) {
      this.isIOS = true;
    } else {
      this.isIOS = false;
    }
  },
}
