// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/impact.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*cssreset*/\nbody,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,input,form,a,p,textarea{\n    padding: 0;\n    margin: 0;\n}\nbody{\n    font-family: \"Helvetica Neue\", Helvetica, Arial, \"Microsoft Yahei UI\", \"Microsoft YaHei\", SimHei, \"\\5B8B\\4F53\", simsun, sans-serif;\n}\n* {\n    margin: 0;\n    padding: 0;\n    border: 0;\n    word-break: break-word;\n    background-size: 100% 100%;\n    -webkit-mask-size: 100% 100%;\n    -webkit-tap-highlight-color: transparent;\n    line-height: 1\n}\ninput[type=number],input[type=password],input[type=tel],input[type=text],select,textarea {\n    -webkit-appearance: none;\n    border: none;\n    border-radius: 0;\n    background-color: transparent\n}\ninput::-webkit-input-placeholder,input:focus::-webkit-input-placeholder {\n    color: #555\n}\n\nol,ul,li{\n    list-style: none;\n}\nimg,input,select {\n    vertical-align: middle;\n    border: none;\n    outline: 0\n}\ntextarea {\n    resize: none\n}\ntextarea:focus {\n    outline: 0\n}\na{\n    text-decoration: none;\n    display: inline-block\n}\nimg{\n    border: none;\n    display: block;\n}\ntable {\n    /* border-collapse: collapse;\n    border-spacing: 1 */\n\n}\ni,em,b{\n    font-style: normal;\n}\nhtml,body,#__nuxt,#__layout {\n  width: 100%;\n  height: 100%;\n  background: #f3f3f3;\n}\n\n.clearfloat{\n    zoom:1;\n}\n.clearfloat:after{\n    display: block;\n    clear: both;\n    content: \"\";\n    visibility: hidden;\n    height: 0;\n}\n/*惯性滚动*/\n.smoothSlide {\n    -webkit-overflow-scrolling: touch;\n}\n/*文字阴影*/\n.txtShadow {\n    text-shadow: .02rem 0 0 #fff,-.02rem 0 0 #fff,0 .02rem 0 #000,0 -.02rem 0 #000\n}\n@font-face {\n  font-family: 'Impact';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
