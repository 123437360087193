export const CommentList = () => import('../../components/comment/CommentList.vue' /* webpackChunkName: "components/comment-list" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/comment/index.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const Float = () => import('../../components/float/float.js' /* webpackChunkName: "components/float" */).then(c => wrapFunctional(c.default || c))
export const FloatMIndex = () => import('../../components/float/m_index.vue' /* webpackChunkName: "components/float-m-index" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterMIndex = () => import('../../components/footer/m_index.vue' /* webpackChunkName: "components/footer-m-index" */).then(c => wrapFunctional(c.default || c))
export const IndexHome = () => import('../../components/index/home.js' /* webpackChunkName: "components/index-home" */).then(c => wrapFunctional(c.default || c))
export const Index = () => import('../../components/index/index.vue' /* webpackChunkName: "components/index" */).then(c => wrapFunctional(c.default || c))
export const IndexMIndex = () => import('../../components/index/m_index.vue' /* webpackChunkName: "components/index-m-index" */).then(c => wrapFunctional(c.default || c))
export const InsideBanner = () => import('../../components/inside/banner.vue' /* webpackChunkName: "components/inside-banner" */).then(c => wrapFunctional(c.default || c))
export const InsideMBanner = () => import('../../components/inside/m_banner.vue' /* webpackChunkName: "components/inside-m-banner" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/loading/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/navigation/index.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationMIndex = () => import('../../components/navigation/m_index.vue' /* webpackChunkName: "components/navigation-m-index" */).then(c => wrapFunctional(c.default || c))
export const PlayerImgplayer = () => import('../../components/player/imgplayer.vue' /* webpackChunkName: "components/player-imgplayer" */).then(c => wrapFunctional(c.default || c))
export const PlayerMImgplayer = () => import('../../components/player/m_imgplayer.vue' /* webpackChunkName: "components/player-m-imgplayer" */).then(c => wrapFunctional(c.default || c))
export const PlayerMPlayer = () => import('../../components/player/m_player.vue' /* webpackChunkName: "components/player-m-player" */).then(c => wrapFunctional(c.default || c))
export const Player = () => import('../../components/player/player.vue' /* webpackChunkName: "components/player" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../components/toast/index.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const CommentApi = () => import('../../components/comment/api/index.js' /* webpackChunkName: "components/comment-api" */).then(c => wrapFunctional(c.default || c))
export const CommentApiRequest = () => import('../../components/comment/api/request.js' /* webpackChunkName: "components/comment-api-request" */).then(c => wrapFunctional(c.default || c))
export const CommentEditorEditable = () => import('../../components/comment/editor/editable.vue' /* webpackChunkName: "components/comment-editor-editable" */).then(c => wrapFunctional(c.default || c))
export const CommentEditorEmojiPicker = () => import('../../components/comment/editor/emoji-picker.vue' /* webpackChunkName: "components/comment-editor-emoji-picker" */).then(c => wrapFunctional(c.default || c))
export const CommentEditorImagePicker = () => import('../../components/comment/editor/image-picker.vue' /* webpackChunkName: "components/comment-editor-image-picker" */).then(c => wrapFunctional(c.default || c))
export const CommentEditor = () => import('../../components/comment/editor/index.vue' /* webpackChunkName: "components/comment-editor" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
