
import { mapState } from 'vuex';
import Navigation from '@/components/navigation/index';
import Footer from '@/components/footer/index';
import InsideBanner from '@/components/inside/banner';
import Float from '@/components/float/index';
import Player from '@/components/player/player';
export default {
  components: {
    Navigation,
    InsideBanner,
    Player,
    Footer,
    Float
  },
  data() {
    return {
      scrollTop: 0,
      height: 0
    }
  },
  head () {
    return {
      ...this.$store.state.seo,
      script: [
        {
          src: '/script/flexible.js',
          type: 'text/javascript',
          charset: 'utf-8'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      floatBanner: state => state.floatBanner,
      insideTopBanner: state => state.insideTopBanner
    })
  },

  mounted() {
    let element = this.$refs['layout-wrp'];
    let rect = element.getBoundingClientRect();
    this.height = rect.height;

    // 滚动条事件
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    // 滚动
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 滚动条偏移量
      this.scrollTop = scrollTop;
    }
  }
}
