export const state = _ => ({
  locales: [
    {
      language: "en",
      langname: "English",
      content: "1"
    },
    {
      language: "th",
      langname: "ภาษาไทย",
      content: "1"
    },
    {
      language: "id",
      langname: "Indonesian",
      content: "1"
    },
    {
      language: "hi",
      langname: "हिन्दी",
      content: "1"
    },
    {
      language: "ru",
      langname: "русский язык",
      content: "1"
    },
    {
      language: "pt",
      langname: "Português",
      content: "1"
    },
    {
      language: "es",
      langname: "España",
      content: "1"
    }
  ],
  locale: "en",
  floatBanner: true,
  autoShowFloat: true,
  insideTopBanner: false,
  seo: null,
  download: [],
  indexVideo: null,
  player: {
    url: "",
    visible: false
  },
  terminal: "",
  noticesType: false
});

export const mutations = {
  // 设置语言
  SET_LANG(state, locale) {
    let ls = state.locales.map(item => {
      return item.language;
    });
    if (ls.includes(locale) !== -1) {
      state.locale = locale;
    }
  },
  // 设置语言配置项
  SET_LOCALES(state, locales) {
    state.locales = locales;
  },
  // 设置内页的topbanner的显示
  SET_INSIDETOPBANNER(state, value) {
    state.insideTopBanner = value;
  },
  SET_AUTOSHOWFLOAT(state, value) {
    state.autoShowFloat = value;
  },
  // 设置底部浮动banner
  SET_FLOATBANNER(state, value) {
    state.floatBanner = value;
  },
  // 设置播放器
  SET_PLAYER(state, { url = "", visible = false }) {
    state.player.url = url;
    state.player.visible = visible;
  },
  // 设置用户终端 PC or m
  SET_TERMINAL(state, value) {
    state.terminal = value;
  },
  // 设置seo
  SET_SEO(state, value) {
    state.seo = value;
  },
  // 设置下载地址
  SET_DOWNLOAD(state, value) {
    state.download = value;
  },
  // 设置首页视频
  SET_INDEXVIDEO(state, value) {
    state.indexVideo = value;
  },
  //设置notices跳转
  SET_NOTICESTYPE(state, value) {
    state.noticesType = value;
  }
};

export const actions = {
  // nuxtServerInit 服务端函数
  async nuxtServerInit({ state, commit }, context) {
    let { $axios } = context;
    try {
      // 获取下载链接
      let down_res = await $axios(
        "/api/basicdata/list?site=supersus&flag=index_download_links"
      );
      console.log("down_res:", down_res);
      let config = Array.from(down_res.data).map(item => {
        let data = {};
        // .apple,.google,.tap,.apk,.nowgg
        let name = item.title.split(",")[0];
        if (name === "AppStore") {
          data.classname = "apple";
        }
        if (name === "GooglePlay") {
          data.classname = "google";
        }
        if (name === "TapTap") {
          data.classname = "tap";
        }
        if (name === "Apk") {
          data.classname = "apk";
        }
        if (name === "Nowgg") {
          data.classname = "nowgg";
        }
        if (name === "steam") {
          data.classname = "steam";
        }
        return Object.assign({}, data, item);
      });
      commit("SET_DOWNLOAD", config);
      // 获取首页视频
      let video_res = await $axios(
        "/api/basicdata/list?site=supersus&flag=index_videos"
      );
      let index_video = video_res.data[0] || [];
      commit("SET_INDEXVIDEO", index_video);
      // 获取页面信息
      let res = await $axios("/api/site/info");

      // 设置页面seo数据
      let title = res.data.web_title;
      let keywords = res.data.seo_keywords;
      let description = res.data.seo_description;
      let seo = {
        title,
        meta: [
          {
            hid: "description",
            name: "description",
            content: description
          },
          {
            hid: "keywords",
            name: "keywords",
            content: keywords
          }
        ]
      };
      commit("SET_SEO", seo);
      // 将英语始终放第一位
      // if(state.locales.length) {
      //   return;
      // }
      let temp = {};
      let languages = Array.from(res.data.languages);
      languages.forEach((item, index) => {
        if (item.language === "en") {
          temp = item;
          languages.splice(index, 1);
        }
      });
      languages.unshift(temp);

      for (let i = 0; i < languages.length; i++) {
        if (languages[i].language === "zh_cn") {
          languages.splice(i, 1);
        }
      }

      commit("SET_LOCALES", languages);
    } catch (e) {
      console.log(e);
    }
  }
};
// function getcookie(cookie, key) {
//   if(!cookie) { return; }
//   const cookies = cookie.split(';');
//   for(let i = 0; i < cookies.length; i++) {
//     const c = cookies[i];
//     const [k, v] = c.split('=');
//     if(k === key) {
//       return v
//     }
//   }
// }
