import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
export default({ app, store }) => {
  app.i18n = new VueI18n({
    locale: store.state.locale,
    fallbackLocale: 'en', // 默认语言
    messages: {
      en: require('@/locales/en-US.json'),
      id: require('@/locales/id.json'),
      th: require('@/locales/th.json'),
      hi: require('@/locales/hi.json'),
      ru: require('@/locales/ru.json'),
      pt: require('@/locales/pt.json'),
      es: require('@/locales/es.json')
    },
    silentFallbackWarn: true
  })
  app.i18n.path = (link, m) => {
    if(m) {
      return `/m/${app.i18n.locale}/${link}`
    }
    return `/${app.i18n.locale}/${link}`
  }
}
