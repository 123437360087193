export default function(args) {
  let { $axios, isDev, store, params, $winstonLog } = args;
  // 请求拦截器
  $axios.interceptors.request.use(config => {
    // if(config.url.includes('/act/')) {
    //   config.baseURL = process.env.MODE.baseURL;
    // }else{
    //   config.baseURL = process.env.MODE.ucmsURL;
    // }

    let language = params.lang || store.state.locale || "en";
    // 拦截下载地址url请求
    if (config.url.includes("flag=index_download_links")) {
      language = "zh_cn";
    }
    if (config.url.includes("flag=privacy")) {
      language = "zh_cn";
    }
    if (config.url.includes("flag=service")) {
      language = "zh_cn";
    }
    if (config.url.includes("flag=deletion")) {
      language = "zh_cn";
    }
    if (config.url.includes("flag=m-privacy")) {
      language = "zh_cn";
    }
    if (config.url.includes("flag=m-service")) {
      language = "zh_cn";
    }
    if (config.url.includes("flag=m-deletion")) {
      language = "zh_cn";
    }
    console.log(language);
    if (process.server && !isDev) {
      config.baseURL = process.env.MODE.ucmsURL;
      config.headers.HOST = "www.supersus.io";
    } else {
      config.baseURL = process.env.MODE.baseURL;
      delete config.headers.HOST;
    }
    let data = { site: "supersus", language };
    if (config.method.toUpperCase() === "GET") {
      data = Object.assign({}, data, config.params);
      config.params = { ...data, _v: "20240214" };
    } else {
      data = Object.assign({}, data, config.data);
      data._v = "20240214";
      config.data = data;
    }
    return config;
  });

  // 响应拦截器
  $axios.interceptors.response.use(response => {
    const status = response.status;
    if ($winstonLog) {
      $winstonLog.info(`[${response.status}] ${response.request.path}`);
    }
    if (status === 200) {
      const res = response.data;
      return res;
    } else {
      const msg = `服务端异常，response:${response}`;
      console.log(msg);
    }
  });

  $axios.onError(err => {
    console.log("axios.onError:", err);
    if ($winstonLog) {
      $winstonLog.error(err);
    }
  });
}
