export default function (context) {
  let {
    route,
    redirect,
    store,
    app
  } = context;
  // let initlang = app.$cookies.get('initlang');
  // let lang = app.$cookies.get('lang');
  // if(!lang) {
  //   app.i18n.locale = initlang;
  // }
  // if(route.name === 'index') {
  //   if(app.$device.isMobile) {
  //     const url = app.i18n.path('', 'm');
  //     redirect(url);
  //   }else{
  //     const url = app.i18n.path('')
  //     redirect(url);
  //   }
  // }
  // 判断用户终端设备
  // if(app.$device.isMobile) {
  //   // 跳转到移动端首页
  //   // if(route.name === 'index') { redirect(app.i18n.path('', 'm')) }

  //   // 跳转到移动端地址
  //   const toReplace = '^/' + route.params.lang + (route.fullPath.indexOf('/' + route.params.lang + '/') === 0 ? '/' : '');
  //   const re = new RegExp(toReplace);
  //   let url = route.fullPath.replace(re, '');
  //   if(!route.name.includes('m-lang') && route.name !== 'index') {
  //     redirect(app.i18n.path(url, 'm'));
  //   }
  // }else{
  //   // 跳转到PC端首页
  //   // if(route.name === 'index') { redirect(app.i18n.path('')) }

  //   // 跳转到PC端地址
  //   const toReplace = '^/m/' + route.params.lang + (route.fullPath.indexOf('/m/' + route.params.lang + '/') === 0 ? '/' : '');
  //   const re = new RegExp(toReplace);
  //   let url = route.fullPath.replace(re, '');
  //   if(route.name.includes('m-lang') && route.name !== 'index') {
  //     redirect(app.i18n.path(url));
  //   }
  // }

  // 设置浮动
  if (!route.name) {
    return;
  }

  if (route.name === 'lang' || route.name === 'm-lang' || route.path.includes('notices')) {
    store.commit('SET_INSIDETOPBANNER', false);
  } else {
    store.commit('SET_INSIDETOPBANNER', true);
  }
}
