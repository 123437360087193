
import Navigation from './navigation.js';
export default {
  mixins: [Navigation],
  data() {
    return {
      vshow: true
    }
  }
}
