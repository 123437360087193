// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/video_close.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".player-wrapper[data-v-718ff069]{position:fixed;left:0;top:0;right:0;bottom:0;z-index:999;width:100%;height:100%;background:rgba(0,0,0,0.8)}.player-wrapper .video-box[data-v-718ff069]{position:absolute;left:0;right:0;top:0;bottom:0;width:1100px;height:640px;margin:auto}.player-wrapper .video-box .close[data-v-718ff069]{position:absolute;width:45px;height:45px;right:-55px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.player-wrapper .video-box iframe[data-v-718ff069]{width:100%;height:100%;object-fit:fill}.player-wrapper .video-box video[data-v-718ff069]{width:100%;height:100%;object-fit:fill}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
