
import { mapState } from 'vuex';
import Navigation from '@/components/navigation/m_index';
import Footer from '@/components/footer/m_index';
import InsideBanner from '@/components/inside/m_banner';
import Float from '@/components/float/m_index';
export default {
  components: {
    Navigation,
    InsideBanner,
    Footer,
    Float

  },
  data() {
    return {
      scrollTop: 0
    }
  },
  head () {
    return {
      ...this.$store.state.seo,
      script: [
        {
          src: '/script/rem.js',
          type: 'text/javascript',
          charset: 'utf-8'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      floatBanner: state => state.floatBanner,
      insideTopBanner: state => state.insideTopBanner
    })
  },
  mounted() {
    // 滚动条事件
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    // 滚动
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; // 滚动条偏移量
      this.scrollTop = scrollTop;
    }
  }
}
