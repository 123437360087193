import { render, staticRenderFns } from "./index.vue?vue&type=template&id=461e663d&scoped=true"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=461e663d&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461e663d",
  null
  
)

export default component.exports