export default function(context) {
  let { isHMR, app, store, params, redirect } = context;
  // console.log('params:', params)
  const defaultLocale = app.i18n.fallbackLocale;
  if(isHMR) { return };
  // 如果用户没有设置过语言，则根据浏览器语言设置页面语言
  let locale = params.lang || app.$cookies.get('lang') || defaultLocale;
  // 没找到语抛出错误
  let result = store.state.locales.some(item => item.language === locale);
  if (!result) {
    if(app.$device.isMobile) {
      redirect('/m/pages/404.vue')
    }else{
      redirect('/pages/404.vue')
    }
    // return error({ message: 'This page could not be found.', statusCode: 404 })
  }
  store.commit('SET_LANG', locale);
  app.i18n.locale = store.state.locale;
}
