import Vue from 'vue';
import Toast from '@/components/toast';

const MessageBox = Vue.extend(Toast); // 创建的是一个组件构造器，不是实例
const Message = {
  install: (options, type, duration) => {
    if (options === undefined || options === null) {
      options = {
        content: ''
      };
    } else if (typeof options === 'string' || typeof options === 'number') {
      options = {
        content: options
      };
      if (type !== undefined && options !== null) {
        options.type = type;
      }
    }
    if (!options.content) { return; }
    let instance = new MessageBox({
      data: options
    }).$mount();
    document.body.appendChild(instance.$el) // 添加dom元素
    Vue.nextTick(() => { // dom元素渲染完成后执行回调
      instance.visible = true
    })
  }
};
Vue.prototype.$toast = Message.install;
['info', 'success', 'error'].forEach((type) => {
  Vue.prototype.$toast[type] = (content) => {
    return Vue.prototype.$toast(content, type);
  };
})

// 使用
// this.$toast("这是一条普通消息");
// this.$toast.success("这是一条成功消息");
// this.$toast.error("这是一条失败消息");

Vue.use(Message);
