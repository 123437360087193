
export default {
  head () {
    return {
      title: this.$t('page.title'),
      meta: [
        { hid: this.$t('page.meta.hid'), name: this.$t('page.meta.name'), content: this.$t('page.meta.content') }
      ]
    }
  }
}
