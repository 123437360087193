
import { mapState } from 'vuex';
export default {
  data() {
    return {
      playerVars: {
        autoplay: 1
      }
    }
  },
  computed: {
    ...mapState({
      player: state => state.player
    })
  },
  methods: {
    closePlayer() {
      this.$store.commit('SET_PLAYER', { visible: false });
    },
    ended() {
      this.$store.commit('SET_PLAYER', { visible: false });
    }
  }
}
