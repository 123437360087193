export default {
  props: {
    scrollTop: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      navigator: null,
      drawerShow: false
    }
  },
  watch: {
    isShow(value) {
      if(!value) {
        this.drawerShow = false;
      }
    }
  },
  computed: {
    isShow() {
      return this.$store.state.floatBanner;
    }
  },
  mounted () {
    this.navigator = navigator;
  },
  methods: {
    // 下载
    // gtag_report_conversion(app) {
    //   const google_id = app.title.split(',')[1];
    //   const url = app.link;
    //   let callback = function () {
    //     if (typeof (url) !== 'undefined') {
    //       window.location = url;
    //     }
    //   };
    //   if(this.$gtag) {
    //     this.$gtag('event', 'conversion', {
    //       send_to: google_id,
    //       event_callback: callback
    //     });
    //   }else{
    //     callback();
    //   }
    //   return false;
    // },
    download() {
      if(this.$device.isAndroid) {
        this.drawerShow = true;
      }
    },
    closeFloat() {
      this.drawerShow = false;
      this.$store.commit('SET_AUTOSHOWFLOAT', false);
      this.$store.commit('SET_FLOATBANNER', false);
    }
  }
}
