
export default {
  data() {
    return {
      content: '',
      visible: false,
      type: 'info'
    }
  },
  mounted() {
    setTimeout(() => {
      this.visible = false;
    }, 2000);
  }
}
